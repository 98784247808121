<template>
  <el-collapse accordion>
    <el-collapse-item
      v-for="item in dataList"
      :key="item.id"
      :title="`Продажа ${ getDate(item.createdAt) }`"
      :name="item.id"
    >
      <span><b>Сумма продажи:</b> {{ getPriceToFormat(item.price) }} р.</span>
      <span><b>Тип оплаты:</b> {{ getTypePay(item) }}</span>

      <span><b>Товары:</b></span>
      <div
        v-for="product in item.products"
        :key="product"
        class="padding-left-20"
      >
        <span>{{ getTitleById(product, 'products') }}</span>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from 'vuex'
import { getTitleById, getPriceToFormat } from '@/helper'

import moment from 'moment/moment'

export default {
  name: 'InfoBlock',
  props: {
    title: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => ([])
    },
    dataInfo: {
      type: Object,
      default: () => ({})
    },
    dataList: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    ...mapGetters('settings', ['getDefaultValues']),
  },
  methods: {
    getTitleById,
    getPriceToFormat,
    getDate (date) {
      return moment(date).format('dd - DD.MM.YYYY HH:mm')
    },
    getTypePay (item) {
      return this.getDefaultValues?.typePay?.filter(el => el.code === item.typePay)[0]?.name
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />