<template>
  <div
    class="card info-box-wrapper"
    :class="getClassList"
  >
    <div class="text">{{ getText }}</div>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
import { getPriceToFormat, isDesktop } from '@/helper'

export default {
  name: 'InfoBox',
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: [String, Number],
      default: ''
    },
    color: {
      type: String,
      default: 'white'
    }
  },
  computed: {
    getClassList () {
      const device = isDesktop() ? 'desktop' : 'mobile'

      return [
        `info-box-color-${this.color}`,
        `info-box-wrapper__${ device }`
      ]
    },
    getText () {
      return this.type === 'cash'
        ? `${ getPriceToFormat(this.text) } р.`
        : this.text
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
