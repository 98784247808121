<template>
  <el-card class="box-card box-control">
    <div class="box-control-title">Кнопки быстрого доступа</div>

    <div class="block-buttons margin-top-0">
      <el-popconfirm
        v-if="getOpenedWorkingShift"
        class="hot-btn"
        title="Точно закрыть смену?"
        @confirm="close"
      >
        <el-button
          slot="reference"
          class="el-button-h-40"
          plain
          type="warning"
        >
          <span>Закрыть смену</span>
        </el-button>
      </el-popconfirm>

      <el-button
        v-else
        class="hot-btn el-button-h-40"
        plain
        type="primary"
        @click="open"
      >
        <span>Открыть смену</span>
      </el-button>

      <el-button
        v-if="getOpenedWorkingShift"
        class="hot-btn el-button-h-40"
        plain
        type="primary"
        @click="createSales"
      >
        <span>Создать продажу</span>
      </el-button>

      <el-button
        v-if="getOpenedWorkingShift"
        class="hot-btn el-button-h-40"
        plain
        type="primary"
        @click="createCashOperations(true)"
      >
        <span>Операции по кассе</span>
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import tableMethods from '@/mixins/tableMethods'

export default {
  name: 'HotActions',
  mixins: [tableMethods],
  computed: {
    ...mapGetters('workingShift', ['getOpenedWorkingShift'])
  },
  methods: {
    ...mapActions('workingShift', ['open', 'close'])
  }
}
</script>

<style lang="scss" src="./style.scss" />
