<template>
  <div
    class="main-wrapper page"
    :class="getClassWrapper"
  >
    <hot-actions />

    <el-card
      v-if="isShowCardInfo"
      class="box-card box-control"
      :class="getClass"
    >
      <el-row :gutter="20">
        <el-col
          v-for="field in getFieldsInfoCashBox"
          :key="field.name"
          :span="6"
        >
          <el-statistic
            group-separator=" "
            decimal-separator=""
            :precision="2"
            :title="field.title"
          >
            <template slot="suffix">
              <span>р.</span>
            </template>
            <template slot="formatter">
              <span>{{ getPriceToFormat(getValue(field)) }}</span>
            </template>
          </el-statistic>
        </el-col>
      </el-row>
    </el-card>

    <el-card
      v-if="getFieldsInfoSales.length"
      class="box-card box-control"
    >
      <div class="block-buttons margin-top-0">
        <div class="info-box-list mt-4">
          <info-box
            v-for="field in getFieldsInfoSales"
            :key="field.name"
            :text="getMainData?.sales?.[field.name]?.value"
            :type="getMainData?.sales?.[field.name]?.type"
            :title="field.title"
            :color="field.color"
          />
        </div>
      </div>
    </el-card>

    <el-card
      v-if="isShowCardSales"
      class="box-card box-control"
    >
      <div class="margin-top-0">
        <info-block
          :data-list="salesByWorkingShift"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfoBlock from '@/components/infoBlock'
import InfoBox from '@/components/infoBox'
import HotActions from '@/components/hotActions'
import { getPriceToFormat, isMobile } from '@/helper'

export default {
  name: 'Main',
  components: {
    InfoBlock,
    InfoBox,
    HotActions
  },
  data() {
    return {
      salesByWorkingShift: []
    }
  },
  computed: {
    ...mapGetters('workingShift', ['getOpenedWorkingShift', 'getIsDataLoaded']),
    ...mapGetters('settings', ['getFields', 'getPermissions']),
    ...mapGetters('user', ['isAccessAllowed']),
    ...mapGetters('info', {
      getMainData: 'getMainData',
      getIsInfoDataLoaded: 'getIsDataLoaded'
    }),
    getFieldsInfoSales () {
      return this.getFields?.info?.sales || []
    },
    getFieldsInfoCashBox () {
      return this.getFields?.info?.cashBox || []
    },
    isShowSales () {
      return this.getPermissions?.view?.includes('all') || this.getPermissions?.view?.includes('sales_to_main')
    },
    isShowCardInfo () {
      return this.getFieldsInfoCashBox.length && this.getIsInfoDataLoaded
    },
    isShowCardSales () {
      return this.isShowSales && this.salesByWorkingShift.length
    },
    getClassWrapper () {
      return isMobile() ? 'page__mobile' : ''
    },
    getClass () {
      return isMobile() ? 'statistic__mobile' : ''
    }
  },
  watch: {
    async getIsDataLoaded (newValue) {
      if (!newValue || !this.getOpenedWorkingShift?.id) {
        return
      }

      await this.loadMainData()
      await this.loadSalesData()
    }
  },
  async created () {
    await this.loadData()

    this.$bus.on('load-sales-main-data', this.loadSalesData)
  },
  beforeDestroy () {
    this.$bus.on('load-sales-main-data', this.loadSalesData)
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('workingShift', { loadDataWorkingShift: 'loadData' }),
    ...mapActions('products', { loadDataProducts: 'loadData' }),
    ...mapActions('users', { loadDataUsers: 'loadData' }),
    ...mapActions('info', ['loadMainData']),
    ...mapActions('sales', ['getDataByOpenedWorkingShift']),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataWorkingShift()
      await this.loadDataProducts()

      if (this.getIsDataLoaded && this.getOpenedWorkingShift?.id) {
        await this.loadMainData()
        await this.loadSalesData()
      }

      if (this.isAccessAllowed) {
       await this.loadDataUsers()
      }

      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
    async loadSalesData () {
      this.salesByWorkingShift = [...await this.getDataByOpenedWorkingShift()].reverse()
    },
    getPriceToFormat,
    getValue (field) {
      const dataInfo = this.getMainData?.cashBox
      const result = dataInfo?.[field.name]

      return Number(result?.value) || 0
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />